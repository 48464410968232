/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { AccountInfo, EndSessionPopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsUserCredential } from '@microsoft/teamsfx';
import { msalConfig } from 'authConfig';
import { deepRefresh, getBaseTenant, getTenant } from 'helpers/utils';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountInfo, setTenant, setToken } from 'redux/slices/auth';
import { RootState } from 'redux/store';
import { AppContext } from './AppContext';
import {
  PERMISSIONS,
  getAppToken,
  loginRequest,
  loginToTeams,
  persistAppTenant,
  persistAppToken,
  persistBaseTenant,
  removeAppTenant,
  removeAppToken,
  removeBaseTenant,
} from './helpers';

const TOKEN_REFRESH_INTERVAL = parseInt(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL ?? '900', 10);

export const azureMsConfig = { ...msalConfig };
if (azureMsConfig.auth.redirectUri) {
  const redirectUrl = new URL(azureMsConfig.auth.redirectUri);
  azureMsConfig.auth.redirectUri = redirectUrl?.origin ?? '';
}

export const msalInstance = new PublicClientApplication(azureMsConfig);
msalInstance.enableAccountStorageEvents();

enum AuthenticationState {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

type AuthStateProps = {
  authenticationState: AuthenticationState;
  error?: string;
  organizations?: { id: string; displayName: string }[];
  orgLoading?: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  clearSession: () => Promise<void>;
  acquirePermissions?: () => Promise<void>;
  setAuthorizationError?: (callback: (context: microsoftTeams.Context) => void) => void;
};

export const AuthContext = React.createContext<AuthStateProps>({
  authenticationState: AuthenticationState.Unauthenticated,
  login: async () => {},
  logout: async () => {},
  clearSession: async () => {},
});

type TokenInfo = {
  name: string;
  preferred_username: string;
  tid: string;
};

const AuthContextProvider: React.FC = ({ children }) => {
  const { isInTeams, refreshTenantData } = useContext(AppContext);
  const persistedToken = getAppToken();
  const [error, setError] = useState<string>();
  const dispatch = useDispatch();
  const { tenant, accountInfo, tenantData } = useSelector((state: RootState) => state.auth);
  const tokenInterval = TOKEN_REFRESH_INTERVAL * 1000;

  const acquirePermissions = async (): Promise<void> => {
    const permissions = PERMISSIONS;

    if (isInTeams) {
      try {
        permissions.push(`${azureMsConfig.auth.clientId}/access_as_user`);
        const { scope } = { scope: permissions };
        initTeamsConfiguration();
        const credential = new TeamsUserCredential();
        await credential.login(scope);
        deepRefresh();
      } catch (error) {
        console.error(error);
      } finally {
        deepRefresh();
      }
    } else {
      if (getTenant() !== getBaseTenant()) {
        const redirecturi = encodeURIComponent(azureMsConfig.auth.redirectUri);
        const clientId = azureMsConfig.auth.clientId;
        window.location.href = `https://login.microsoftonline.com/${getTenant()}/adminconsent?client_id=${clientId}&redirect_uri=${redirecturi}`;
      } else {
        await msalInstance.acquireTokenSilent({
          scopes: permissions,
        });
      }
    }
  };

  const updateCurrentToken = async (updatedToken: string, user?: AccountInfo) => {
    persistAppToken(updatedToken);
    dispatch(setToken(updatedToken));
    if (user) {
      dispatch(setAccountInfo(user));
      return;
    }
    const tokenInfo = jwtDecode<TokenInfo>(updatedToken);
    if (!tokenInfo) return;
    const acct = {
      username: tokenInfo.preferred_username,
      name: tokenInfo.name,
      tenantId: tokenInfo.tid,
    } as AccountInfo;
    dispatch(setAccountInfo(acct));
    persistBaseTenant(tokenInfo.tid);
  };

  const loginViaTeams = async () => {
    const teamsToken = await loginToTeams();
    if (!teamsToken) return;
    updateCurrentToken(teamsToken);
  };

  const loginViaMsal = async () => {
    if (persistedToken) return;
    try {
      const result = await msalInstance.loginPopup(loginRequest);
      if (!result?.idToken) return;
      updateCurrentToken(result.idToken);
    } catch (e) {
      const err = JSON.parse(JSON.stringify(e)) as { errorMessage: string };
      const message = err?.errorMessage?.includes('\r\n')
        ? err.errorMessage.split('\r\n')?.[0]?.split(':')?.[1]
        : err.errorMessage;
      setError(message);
    }
  };

  const refreshTokenMsal = async () => {
    if (!accountInfo) return;
    const account = msalInstance.getAccountByUsername(accountInfo.username);
    if (!account) return;
    const result = await msalInstance.acquireTokenSilent({ ...loginRequest, account });
    updateCurrentToken(result.idToken, account);
  };

  const login = async () => {
    if (isInTeams) {
      await loginViaTeams();
    } else {
      await loginViaMsal();
    }
  };

  const clearSession = async () => {
    dispatch(setToken(undefined));
    dispatch(setTenant(undefined));
    dispatch(setAccountInfo(undefined));
    removeAppToken();
    removeAppTenant();
    removeBaseTenant();
  };

  const logout = async () => {
    if (isInTeams || !accountInfo) return;
    const logoutRequest: EndSessionPopupRequest = {
      account: msalInstance.getAccountByUsername(accountInfo.username),
      mainWindowRedirectUri: '/',
    };
    await msalInstance.logoutPopup(logoutRequest);
    clearSession();
  };

  const setAuthorizationError = (callback: (context: microsoftTeams.Context) => void) => {
    if (!isInTeams) return;
    microsoftTeams.getContext(callback);
  };

  useEffect(() => {
    if (!persistedToken) return;
    if (isInTeams) {
      removeAppToken();
      return;
    }
    updateCurrentToken(persistedToken);
  }, []);

  useEffect(() => {
    if (isInTeams) {
      loginViaTeams();
    }
    const ctr = setInterval(() => {
      if (isInTeams) loginViaTeams();
      else refreshTokenMsal();
    }, tokenInterval);
    return () => clearInterval(ctr);
  }, [isInTeams]);

  useEffect(() => {
    if (!tenant || !!tenantData) return;
    persistAppTenant(tenant);
    refreshTenantData();
  }, [tenant, tenantData]);

  const value = {
    authenticationState: AuthenticationState.Authenticated,
    error,
    login,
    logout,
    clearSession,
    acquirePermissions,
    setAuthorizationError,
  };

  return (
    <>
      {isInTeams ? (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      ) : (
        <MsalProvider instance={msalInstance}>
          <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
        </MsalProvider>
      )}
    </>
  );
};

export default AuthContextProvider;
function initTeamsConfiguration() {
  throw new Error('Function not implemented.');
}
