import { Box, Flex, Header, Text } from '@fluentui/react-northstar';

const Forbidden = (): React.ReactElement => {
  return (
    <Flex
      hAlign="center"
      vAlign="center"
      style={{ height: '100vh', backgroundColor: '#f6f6f6', margin: '-0.5rem -1.5rem' }}
    >
      <Flex key="center" hAlign="center" vAlign="center" style={{ width: 350, padding: 20, textAlign: 'center' }}>
        <Box>
          <Header color="brand" as="h1" content="Forbidden" />
          <Text
            size="large"
            content="You are not allowed to access this application. Please contact your administrator to request access."
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Forbidden;
